// START WINDOW SIZE
var $window = $(window),
	$body = $('body');

function windowSize() {
	'use strict';

	var w = Math.max($window.width(), window.innerWidth),
		type = 'Default';

	if (w >= 1441) {
		type = 'XL';
	} else if (w < 1441 && w >= 1281) {
		type = 'Large';
	} else if (w < 1281 && w >= 1024) {
		type = 'Medium';
	} else if (w < 1024 && w >= 440) {
		type = 'Small';
	} else {
		type = 'Default';
	}

	// change the css file of the tag with id="stl" and rel="stylesheet"
	$body.removeClass('XL Large Medium Small Default').addClass(type);
}

// FIND BROWSER IE
function msieversion() {
	var ua = window.navigator.userAgent;
	var msie = ua.indexOf("MSIE ");

	if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./) || navigator.userAgent.indexOf("Edge") > 0) {  // If Internet Explorer, return version number
		//alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
		$('body').addClass('IE');
	}
	else { // If another browser, return 0
		//alert('otherbrowser');
	}
	return false;
}

// FIND APPLE DEVICE
function isAppleDevice() {
	"use strict";
	if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
		return true;
	} else {
		return false;
	}
}

// FIND ANDROID DEVICE
function isAndroidDevice() {
	"use strict";
	if (/Android/i.test(navigator.userAgent)) {
		return true;
	} else {
		return false;
	}
}

// variables set globally to apply to hub home and hub panels on interior
var relatedTermIds = [],
	originalRelatedTermIds = [],
	defaultTermIds = [], // used for resetting term ids array on keyword search
	searchTermIds = '', // used for pulling in dynamic filters (this cannot be an array, otherwise it will not pull in taxonomy only used on pages)
	featuredTermId,
	outputFacetIds = '', //used for pulling in dynamic filters by these other facets
	pages,
	getContentType = 'featured',
	url = window.location.href;

	if(url.indexOf('.devs.') > 0 || url.indexOf('.dev.') > 0){
		originalRelatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		relatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		defaultTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		searchTermIds = "1694175d-cc13-e711-9d2a-2c768a4e1b84,ad9e776f-cc13-e711-9d2a-2c768a4e1b84,ba0a7b63-cc13-e711-9d2a-2c768a4e1b84";
		featuredTermId = "6dedfc20-7922-ee11-a88c-000d3a619f08";
		outputFacetIds = "4c842b00-6d35-49a7-a581-ae331f2ecba2,b50797da-4932-4535-bb24-defe7b13cd6f,ce9349e1-1bda-4c59-af2f-e2dedcc42790";
	}
	else if(url.indexOf('.stage.') > 0){
		originalRelatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		relatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		defaultTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		searchTermIds = "1694175d-cc13-e711-9d2a-2c768a4e1b84,ad9e776f-cc13-e711-9d2a-2c768a4e1b84,ba0a7b63-cc13-e711-9d2a-2c768a4e1b84";
		featuredTermId = "f3fb9f68-b72f-ee11-a873-000d3a604257";
		outputFacetIds = "4c842b00-6d35-49a7-a581-ae331f2ecba2,b50797da-4932-4535-bb24-defe7b13cd6f,ce9349e1-1bda-4c59-af2f-e2dedcc42790";
	}
	else{
		originalRelatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		relatedTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		defaultTermIds = ["1694175d-cc13-e711-9d2a-2c768a4e1b84","ad9e776f-cc13-e711-9d2a-2c768a4e1b84","ba0a7b63-cc13-e711-9d2a-2c768a4e1b84"];
		searchTermIds = "1694175d-cc13-e711-9d2a-2c768a4e1b84,ad9e776f-cc13-e711-9d2a-2c768a4e1b84,ba0a7b63-cc13-e711-9d2a-2c768a4e1b84";
		featuredTermId = "f0529781-b72f-ee11-a864-000d3a611c21";
		outputFacetIds = "4c842b00-6d35-49a7-a581-ae331f2ecba2,b50797da-4932-4535-bb24-defe7b13cd6f,ce9349e1-1bda-4c59-af2f-e2dedcc42790";
	}
	//relatedTermIds, defaultTermIds & searchTermIds should all be the term id of the Content Hub Page Term under the Content Hub Page facet.
	//outputFacetIds should be the facet ids of the 'Service Lines', 'Balance Topic' and 'Balance Author' facets.

	//comments below for default balance content type terms (default relatedTermIds and featuredTermId)
	//1694175d-cc13-e711-9d2a-2c768a4e1b84 - Article
	//ad9e776f-cc13-e711-9d2a-2c768a4e1b84 - Infographic
	//ba0a7b63-cc13-e711-9d2a-2c768a4e1b84 - Video
	//ba0a7b63-cc13-e711-9d2a-2c768a4e1b84 - Featured

// START FUNCTION
$(function() {
	"use strict";

	var $container,
		$featuredContainer,
		totalRecords = '',
		resultText = '',
		filters = {},
		featuredPageId = 0,
		foundFeaturedPage = false,
		featuredPageNotExisting = false,
		featuredItem = [],
		savedItem = [],
		itemCount = 0,
		featuredItemCount = 0,
		keywordValue = '',
		pageSizeValue = 4, // number of items to pull back on page load and subsequent load more
		sort = 11, //default page sort by date (this will change for keyword search)
		pageNum = 1;

	if($('body.ContentHub').length) {
		// SETTING VARIABLES
		$container = $('#container');
		$featuredContainer = $('#container');

		// ADDING ARIA ATTRIBUTES TO CONTAINER FOR ACCESSIBILITY
		$container.attr('aria-live', 'polite');
		$container.attr('aria-relevant', 'additions removals');

		// SETTING PLACEHOLDER ON CONTENT SEARCH
		$('#ContentSearchField').attr('placeholder', 'Search by keyword');
		$('#ContentSearchField').attr('onfocus', 'this.placeholder = ""');
		$('#ContentSearchField').attr('onblur', 'this.placeholder = "Search by keyword"');

		// LOAD FILTER TAXONOMY TERMS TO CREATE THE CHECKBOXES
		loadFilters();

		// CHECKING FOR HASH IN URL
		if (window.location.hash) {
			// WAIT TILL FILTERS HAVE LOADED
			var checkExist = setInterval(function() {
				if ($('.BalanceAuthor .option-set .FilterCloseButton').length > 0) {
					var hashValue = window.location.hash.replace('#', '').replace('.', ''),
						inputHashElement = hashValue.split('+'),
						inputElement,
						inputElementArray = [];

					// LOOPING THROUGH EACH HASH TAG AS A CLASS ON INPUTS TO SEE IF THEY EXIST AND IF SO ADD THEM TO ARRAY
					$.each(inputHashElement, function (i, elm) {
						inputElement = '.option-set input.' + elm;

						if ($(inputElement).length > 0) {
							inputElementArray.push(inputElement);
						}
					});

					// TAKE ARRAY OF HASH INPUTS AND CHECK APPROPRIATE CHECKBOXES ON FILTERS & ADD APPROPRIATE TERM IDS TO relatedTermIds ARRAY FOR THE PAGE API & CREATE FILTER CLOSE BUTTONS
					if ($(inputElementArray).length > 0) {
						var checkboxFunction = function() {
							return $.each(inputElementArray, function (i, elm) {
								manageMultipleCheckboxes( $(elm) );
							});
						};
						$.when( checkboxFunction() ).done(function() {
							// AFTER PREVIOUS FUNCTION IS COMPLETE - LOAD FILTERED CONTENT
							getContentType = 'filteredresults';
							pageSizeValue = 4;
							getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
							runAtPageLoad();
						});
					} else {
						// LOAD INITIAL CONTENT
						relatedTermIds = originalRelatedTermIds;
						getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
						runAtPageLoad();
					}

					clearInterval (checkExist);
				}
			}, 100); // check every 100ms
		} else {
			// LOAD INITIAL CONTENT & RUN MASONRY (FIRST GET CONTENT FOR FEATURED THEN GET All CONTENT (EXCLUDING FEATURED BELOW))
			relatedTermIds = featuredTermId; //content hub featured page term id
			pageSizeValue = 1;
			getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );

			var checkExist2 = setInterval(function() {
				if ($('.featuredItem').length > 0 || featuredPageNotExisting) {
					getContentType = 'allresults';
					pageSizeValue = 4;
					relatedTermIds = originalRelatedTermIds; //content hub page term id
					getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
					runAtPageLoad();
					clearInterval(checkExist2);
				}
				$('.ContentHub #container .item .Title').each(function () {
					//add nowrap span to last word so that last word and arrow don't wrap separately
					if ($(this).find('.nowrap').length) {}
					else {
						$(this).html(function(){
							// separate the text by spaces
							var text= $(this).html().split(' ');
							// drop the last word and store it in a variable
							var last = text.pop();
							// join the text back and if it has more than 1 word add the span tag to the last word
							return text.join(" ") + (text.length >= 0 ? ' <span class="nowrap">'+last+'</span>' : last);
						});
						//add arrow button
						$(this).find('> span').append(' <span class="ArrowIcon" aria-hidden="true"><img role="presentation" alt="" height="19" src="/imgs/icons/green-arrow-outline.svg" width="10" /></span>');
					}
				});
				VideoIcon();
			}, 100); // check every 100ms
		}
	}

	// RUN ON PAGE LOAD AFTER ITEMS ARE LOADED (RESULTS DISPLAY AND OBJECT-FIX FOR IE)
	//====================================================
	function runAtPageLoad() {
		$(window).on( "gotContent", function () {
			var checkExist = setInterval(function() { //WAITING UNTIL CONTENT EXISTS FROM API BEFORE RUNNING ADDITIONAL CHANGES
				if ($('#container .item').length) {   //FIND ITEMS
					$('#TotalResults').remove();

					if(window.location.hash) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when filtering by</div>');
						$('#container').removeAttr('class');
						$('#container').addClass('ItemTotal' + totalRecords);
					}
					clearInterval(checkExist);
				}
				$('.ContentHub #container .item .Title').each(function () {
					//add nowrap span to last word so that last word and arrow don't wrap separately
					if ($(this).find('.nowrap').length) {}
					else {
						$(this).html(function(){
							// separate the text by spaces
							var text= $(this).html().split(' ');
							// drop the last word and store it in a variable
							var last = text.pop();
							// join the text back and if it has more than 1 word add the span tag to the last word
							return text.join(" ") + (text.length >= 0 ? ' <span class="nowrap">'+last+'</span>' : last);
						});
						//add arrow button
						$(this).find('> span').append(' <span class="ArrowIcon" aria-hidden="true"><img role="presentation" alt="" height="19" src="/imgs/icons/green-arrow-outline.svg" width="10" /></span>');
					}
				});
				VideoIcon();
			}, 100); // check every 100ms
		});
	}

	// RUN ON NEW RESULTS (AFTER PAGE LOAD) (RESULTS DISPLAY AND OBJECT-FIX FOR IE)
	//====================================================
	function runAfterPageLoad() {
		//This function has been added to make sure items are reloaded
		$(window).ajaxComplete(function () {
			var checkExist2 = setInterval(function() { //WAITING UNTIL CONTENT EXISTS FROM API BEFORE RUNNING ADDITIONAL CHANGES
				if ($('#container .item').length) {   //FIND ITEMS
					$('#TotalResults, #SearchedBy').remove();

					if($('.FilterItem').length > 0) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when filtering by</div>');
					} else if(keywordValue.length > 0) {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + ' when searching for <span class="Keywords">"' + keywordValue + '".</span></div>');
					} else {
						$('#filter-display').prepend('<div id="TotalResults" aria-live="polite">' + totalRecords + resultText + '.</div>');
					}
					$('#container').removeAttr('class');
					$('#container').addClass('ItemTotal' + totalRecords);

					clearInterval(checkExist2);
				}
				$('.ContentHub #container .item .Title').each(function () {
					//add nowrap span to last word so that last word and arrow don't wrap separately
					if ($(this).find('.nowrap').length) {}
					else {
						$(this).html(function(){
							// separate the text by spaces
							var text= $(this).html().split(' ');
							// drop the last word and store it in a variable
							var last = text.pop();
							// join the text back and if it has more than 1 word add the span tag to the last word
							return text.join(" ") + (text.length >= 0 ? ' <span class="nowrap">'+last+'</span>' : last);
						});
						//add arrow button
						$(this).find('> span').append(' <span class="ArrowIcon" aria-hidden="true"><img role="presentation" alt="" height="19" src="/imgs/icons/green-arrow-outline.svg" width="10" /></span>');
					}
				});
				VideoIcon();
			}, 100); // check every 100ms
		});
	}

	// FEATURED CARD MARKUP
	//====================================================
	var featuredCard = function (page) {
		var element = "<article aria-labelledby='article" + page.pageid + "' class='item featuredItem " + page.balanceContentType + " " + page.contentTopic + " " + page.serviceLines + " " + page.contentAuthor + "' id='" + page.pageid + "'>" +
						"<a class='" + page.balanceContentType + "' href='" + page.url + "'>" + 
							"<span class='ImageWrap2'><span class='ImageWrap'>" + page.image + "</span></span>" +
							"<span class='ContentWrap'>" +
								"<span class='Date'>" + page.newDateFormat + "</span>" +
								"<span class='Title' id='article" + page.pageid + "' role='heading' aria-level='2'>" + page.title + "</span>" + 
								"<span class='Description'>" + page.snippet + "</span>" +
							"</span>" +
						"</a>" +
					"</article>";
		return element;
	};

	// CARD MARKUP
	//====================================================
	var cards = function (page) {
		var element = "<article aria-labelledby='article" + page.pageid + "' class='item " + page.balanceContentType + " " + page.contentTopic + " " + page.serviceLines + " " + page.contentAuthor + "' id='" + page.pageid + "'>" +
				"<a class='" + page.balanceContentType + "' href='" + page.url + "'>" + 
					"<span class='ImageWrap2'><span class='ImageWrap'>" + page.image + "</span></span>" +
					"<span class='ContentWrap'>" +
						"<span class='Date'>" + page.newDateFormat + "</span>" +
						"<span class='Title' id='article" + page.pageid + "' role='heading' aria-level='2'>" + page.title + "</span>" + 
						"<span class='Description'>" + page.snippet + "</span>" +
					"</span>" +
				"</a>" +
					"</article>";
		return element;
	};

	// BUILD OUT FILTERS DYNAMICALLY AND THEN ALLOW THE DROPDOWNS, FILTERS AND SEARCH TO FUNCTION
	//====================================================
	function loadFilters() {
		const apiPath = `/api/taxonomy/facetandobjects/`;
		const objectTypeCode = 19;
		fetch(`${apiPath}${objectTypeCode}?SearchTermIds=${searchTermIds}&OutputFacetIds=${outputFacetIds}`)
		.then(res => res.json())
		.then(data => {
			buildFilterMarkup(data);
			filterDropdowns();
			morefiltersDropdowns();
			filterCheckboxes();
			keywordSearch();
			filterCloseButtons();
			$('.LoadingFilters').remove();
		})
		.catch(console.error);
	}

	// CREATE MARKUP FOR THE FILTERS
	//====================================================
	function buildFilterMarkup(resBody) {
		// the order of the array "facetsToFilter" determines the order they are output to the DOM
		const facetsToFilter = ['Balance Topic', 'Service Line', 'Balance Author'];
		if (resBody.Facets.length > 0) {
			let facetMarkup = resBody.Facets
			.filter(facet => facetsToFilter.includes(facet.FacetName))
			.map(facet => ({
				name: facet.FacetName,
				html: buildFacetMarkup(facet)
			}));

			let html = `` +
			`<div class="BalanceFilterButton">` +
				`<button type="button" class="FilterToggle" id="BalanceFilterButton">` +
					`<span class="FilterText">See more filters</span>` +
					`<span class="FilterIcon">` +
						`<img alt="" height="7" src="/imgs/icons/arrow-select.svg" width="16">` +
					`</span>` +
				`</button>` +
			`</div>` +

			`<div id="filter">` +
				`<div id="options">` +
					`<p>Filter by</p>` +
					`<div id="filter-groups" class="ClearFix">` +
			``;

			// the order of the array "facetsToFilter" determines the order they are output to the DOM
			facetsToFilter.forEach(facetName => {
				facetMarkup.forEach(facetObject => {
					if (facetObject.name === facetName) {
						html += facetObject.html;
					}
				});
			});

			html += `` +
					`</div>` +
				`</div>` +
			`</div>` +
			`<div id="ContentSearchWrapper">` +
				`<div id="Or">or</div>` +
				`<div id="ContentSearch">` +
					`<label class="Hide" for="ContentSearchField">Search Content Hub</label>` +
					`<input id="ContentSearchField" maxlength="128" name="term" type="search" value="" placeholder="Search Balance Blog" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Search Content Hub'">` +
					`<input alt="Search" class="ImgButton" src="/imgs/icons/search-icon-green.svg" type="image">` +
				`</div>` +
			`</div>`;
			
			let queryFilterSearch = document.querySelector('#filter-search');
			queryFilterSearch.innerHTML += html;
			queryFilterSearch.parentElement.innerHTML += `<div id="filter-display" class="ClearFix"></div>`;
		} else {
			console.info(`Content Hub - no facets found`);
		}
	}

	// CREATE INDIVIDUAL FACET MARKUP FOR THE FILTERS
	//====================================================
	function buildFacetMarkup(facet) {
		let facetDisplayName, facetDisplayNamePlural;
		switch(facet.FacetPropertyName) {
			case 'BalanceTopic':
				facetDisplayName = 'Topics';
				facetDisplayNamePlural = 'Topics';
				break;
			case 'ServiceLine':
				facetDisplayName = 'Services';
				facetDisplayNamePlural = 'Services';
				break;
			case 'BalanceAuthor':
				facetDisplayName = 'Authors';
				facetDisplayNamePlural = 'Authors';
				break;
			default:
				break;
		}

		let termsMarkup = ``;
		if (facet.Terms.length > 0) {
			termsMarkup = buildTermsMarkup(facet, facetDisplayNamePlural);
		}

		const hiddenFilters = ['BalanceAuthor'];
		return `` +
		`${hiddenFilters.includes(facet.FacetPropertyName) ? `<div style="display:none;">` : ``}` +
		`${['ServiceLine', 'BalanceAuthor'].includes(facet.FacetPropertyName) ? `<p>and/or</p>` : ``}` +
		`<div class="${facet.FacetPropertyName}">` +
			`<button class="FilterButton" id="${facet.FacetPropertyName}">${facetDisplayName}<span></span></button>` +
			termsMarkup +
		`</div>` +
		`${hiddenFilters.includes(facet.FacetPropertyName) ? `</div>` : ``}` + ``;
	}

	// CREATE INDIVIDUAL TERM MARKUP FOR THE FACET FILTERS
	//====================================================
	function buildTermsMarkup(facet, pluralName) {
		let html = `` +
		`<fieldset class="option-set" data-group="${facet.FacetPropertyName}">` +
			`<legend class="option-set-header ClearFix">${pluralName}</legend>` +
			`<div class="option-set-contents">` +
				`<label for="${facet.FacetPropertyName}-all"><input checked="checked" class="all" id="${facet.FacetPropertyName}-all" type="checkbox" value=""><span class="checkmark"></span> All ${pluralName}</label>` +
		``;

		facet.Terms.forEach(term => {
			if (term.Name != "_No Topic") {
				let termNameClass = term.Name.replace(/[^0-9a-zA-Z]+/g, '');
				html += `<label for="${term.TermId}" ${term.Distance !== 0 ? 'class="ChildTerm"' : ''}><input id="${term.TermId}" class="${termNameClass}" type="checkbox" value="${term.TermId}" /><span class="checkmark"></span> ${term.Name}</label>`;
			}
		});

		html += `` +
			`</div>` +
			`<button class="FilterCloseButton">Close</button>` +
		`</fieldset>` +
		`<div class="FadeOverlay"></div>` +
		``;

		return html;
	}

	// DROP DOWN BUTTON FUNCTIONALITY
	//====================================================
	function filterDropdowns() {
		$('#options button.FilterButton').on('click', function(e) {
			e.preventDefault();
			if($(this).hasClass('Active')) {
				//remove active class to close dropdown on clicked button if was active
				$(this).removeClass('Active');
				$(this).next('.option-set').removeClass('Active');
				$('body').removeClass('NoScroll');
				$('.modal-overlay').remove();
				if($('.stick-it #options button.FilterButton').length > 0) {
					$('.stick-it').removeClass('stick-it-full-height');
				}
			} else {
				//first remove active class from all in case other drop down is open
				$('#options button.FilterButton').removeClass('Active');
				$('#options button.FilterButton').next('.option-set').removeClass('Active');
				//then open clicked on button dropdown
				$(this).addClass('Active');
				$(this).next('.option-set').addClass('Active');
				$('body').addClass('NoScroll');
				$('.modal-overlay').remove();
				$('<div class="modal-overlay"></div>').insertBefore('#filter');
				if($('.stick-it #options button.FilterButton').length > 0) {
					$('.stick-it').addClass('stick-it-full-height');
				}
				if ($('.stick-it #options button.FilterButton').length > 0) {
					$('html, body').animate({
						scrollTop: $('#containerWrap').offset().top
					}, 1000);
				}
				activateFadeOverlay();
			}
		});
	}

	//Hub Filter Toggle for mobile
	function morefiltersDropdowns() {
		$('.BalanceFilterButton').on('click', '.FilterToggle', function () {
			$('#filter-search #filter').slideToggle();
			$(this).toggleClass('Active');
			if($('.FilterToggle.Active').length) {
				$('#filter-search #filter').attr('aria-hidden', 'false');
				$(this).attr('aria-expanded', 'true');
				$(this).find('.FilterText').text('See less filters');
			} else {
				$('#filter-search #filter').attr('aria-hidden', 'true');
				$(this).attr('aria-expanded', 'false');
				$(this).find('.FilterText').text('See more filters');
			}
			return false;
		});
	}

	// DO STUFF ON CHECKBOX CHANGE
	//====================================================
	function filterCheckboxes() {
		$('#options').on( 'change', function( jQEvent ) {
			var $checkbox = $( jQEvent.target );

			//first check to see if .items are available before clearing to know whether it needs function on page load or after page load
			var newPageLoad = true;
			if ($('#container .item').length > 0) {
				newPageLoad = false;
			}

			//THEN CLEAR OUT ELEMENTS IF THERE
			resetPageElements();

			//MANAGING THE CHECKBOXES AND SET NEW VALUES
			manageCheckbox( $checkbox );

			// LOAD NEW RESULTS BASED ON TERMS AND APPLY RESULTS TEXT
			$('input#ContentSearchField').val('');
			keywordValue = ''; //resetting keyword value to be empty so that it doesn't combine the search funcionalities
			sort = 11; //resetting sort value to be based on date
			pageSizeValue = 4;
			getContentType = 'filteredresults';
			getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );

			if (newPageLoad) {
				runAtPageLoad();
			} else {
				runAfterPageLoad();
			}
		});
	}

	// DO STUFF ON FILTERED BY BUTTONS TO TURN OFF FILTERS (BY FORCING A CLICK ON ASSOCIATED INPUT IT WILL RUN THE filterCheckboxes FUNCTION)
	//====================================================
	function filterCloseButtons() {
		$('.ContentHub').on('click', '#filter-display button.FilterItem, #filter-display button input', function(e) {
			e.preventDefault();
			$('.option-set input').each(function() {
				if ($(this).attr('value') === $(e.target).attr('data-id')) {
					$(this).trigger('click');
				}
			});
		});
	}

	// DO STUFF ON KEYWORD SEARCH
	//====================================================
	function keywordSearch() {
		$('input#ContentSearchField').each(function () {
			$(this).on('focus', function() {
				keywordValue = $(this).val();
			});
			$(this).on('blur', function() {
				keywordValue = $(this).val();
			});
			// keyword search on enter key
			$(this, 'input.ImgButton').keydown(function(event) {
				if(event.keyCode === 13) {
					event.preventDefault();
					keywordFunctionality();
				}
			});
			// keyword search on button click
			$('#ContentSearch input.ImgButton').click(function(event) {
				event.preventDefault();
				keywordFunctionality();
			});
		});
	}
	function keywordFunctionality() {
		$('#top-nav').removeClass("stick-it");
		$('html, body').animate({
			scrollTop: 0
		}, 100);
		//setting the error text value
		var emptySearch = '<div class="error">Please enter search criteria.</div>';
		//setting sort order to go by relevancy
		sort = 7;
		//setting the keyword value to what's within the input
		keywordValue = $('input#ContentSearchField').val();
		//resetting the relatedTermIds back to the default content hub page facet terms
		relatedTermIds = defaultTermIds.slice();

		if (keywordValue.length > 0) {
			// remove contents, error message, filtered by buttons, and checkmarks from filters (add checked to all checkmarks)
			resetPageElements();
			$('button.FilterItem').remove();
			$('.option-set input').not('.option-set input.all').prop('checked', false);
			$('.option-set input.all').prop('checked', true);

			// pull back content by keyword
			getContentType = 'filteredresults';
			pageSizeValue = 4;
			getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
			// run results on content
			runAfterPageLoad();
		} else {
			if (!$('#ContentSearch .error').length) {
				$('#ContentSearch').append(emptySearch);
			}
		}
	}

	// PULL IN CONTENT FROM PAGE API - THIS GETS CALLED ON PAGE LOAD, ON KEYWORD SEARCH & ON CHECKBOX FILTERS
	//====================================================
	function getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum ) {
		$('#ViewMore, #TotalResults').remove();

		$.getCmsPages({
			pageNumber: pageNum,
			pageSize: pageSizeValue,
			sort: sort,
			fields  : "PageId, HtmlTitle, publichref, originalPublicationDate, HtmlContent, Images, Taxonomy, Description",
			criteria: {
				originalpublicationbegindate: "01/01/2012",
				relatedTermIds: relatedTermIds,
				keyword: keywordValue
			}
		}, function (data) {
			//store totalRecords in variable
			totalRecords = data.TotalRecords;
			pages = Math.ceil(totalRecords / pageSizeValue);
			resultText = totalRecords > 1 ? ' results': ' result';

			if (getContentType === 'featured' && totalRecords === 0) {
				featuredPageNotExisting = true;
			} else {
				for (var i in data.Values) {
					var cmspage = data.Values[i],
						item = {
							title: cmspage.HtmlTitle,
							pageid: cmspage.PageId,
							url: cmspage.PublicHRef.replace(/~/g, ''),
							newDateFormat: buildPubDate(cmspage),
							snippet: cmspage.Description,
							imageSrc: (typeof cmspage.Images[0] !== 'undefined') ? "/app/files/public/" + cmspage.Images[0].FileName : "/imgs/Hub-Thumb.jpg",
							image: (typeof cmspage.Images[0] !== 'undefined') ? "<img alt='" + cmspage.Images[0].FileTitle + "' src='/app/files/public/" + cmspage.Images[0].FileName + "'/>" : "<img alt='' src='/imgs/Hub-Thumb.jpg' />",
							balanceContentType: '',
							contentTopic: '',
							eachTopicTermWithNobr: '',
							contentTopicTag: '',
							serviceLines: '',
							contentAuthor: '',
							eachAuthorTermWithNobr: '',
							contentAuthorTag: '',
							balanceTerms: [],
							topicTerms: [],
							serviceTerms: [],
							authorTerms: []
						};

					for (i in cmspage.Taxonomy) {

					//set variable to taxonomyy object
						var Taxonomy = cmspage.Taxonomy[i];

						//check for taxonomy = Balance Type
						if (Taxonomy.FacetName === 'Balance Type') {
							//loop through taxonomy for terms
							for (i in Taxonomy.Terms) {
								//set variable to terms object
								var balanceTerm = Taxonomy.Terms[i];
								//add terms to array
								item.balanceTerms.push(balanceTerm.Name);
							}
							item.balanceContentType = (item.balanceTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
						}

						//check for taxonomy = Content Hub Topic
						if (Taxonomy.FacetName === 'Balance Topic') {
						//loop through taxonomy for terms
							for (i in Taxonomy.Terms) {
							//set variable to terms object
								var topicTerm = Taxonomy.Terms[i];
								//add terms to array
								item.topicTerms.push(topicTerm.Name);
							}
							item.contentTopic = (item.topicTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
							//(this takes topics from taxonomy and if it's not undefined & it's not marked "_No Topic" then set any others to this variable), also add in comma between each Topic term
							item.eachTopicTermWithNobr = "<span class='nobr'>" + (item.topicTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
							item.contentTopicTag = (item.topicTerms.toString() !== 'undefined' && item.topicTerms.toString() !== '_No Topic') ? "<div class='Topic'><span class='Dec'>" + item.eachTopicTermWithNobr + "</span></div>" : "";
						}

						//check for taxonomy = Service
						if (Taxonomy.FacetName === 'Service Line') {
						//loop through taxonomy for terms
							for (i in Taxonomy.Terms) {
							//set variable to terms object
								var serviceTerm = Taxonomy.Terms[i];
								//add terms to array
								item.serviceTerms.push(serviceTerm.Name);
							}
							item.serviceLines = (item.serviceTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
						}

						//check for taxonomy = Content Hub Author
						if (Taxonomy.FacetName === 'Balance Author') {
						//loop through taxonomy for terms
							for (i in Taxonomy.Terms) {
							//set variable to terms object
								var authorTerm = Taxonomy.Terms[i];
								//add terms to array
								item.authorTerms.push(authorTerm.Name);
							}
							item.contentAuthor = (item.authorTerms.toString().replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g,''));
							//(this takes authors from taxonomy and if it's not undefined & it's not marked "_No Author" then set any others to this variable), also add in comma between each author term
							item.eachAuthorTermWithNobr = "<span class='nobr'>" + (item.authorTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
							item.contentAuthorTag = (item.topicTerms.toString() !== 'undefined' && item.authorTerms.toString() !== '_No Topic') ? "<div class='Topic'><span>" + item.eachAuthorTermWithNobr + "</span></div>" : "";
						}
					} //end taxonomy loop

					//IF THERE ARE RESULTS & THEY ARE FEATURED RESULTS THEN BUILD OUT BASED ON CARDS DATA
					if (data.Values.length > 0 && getContentType === 'featured') {
						featuredItem.push(item);
						featuredPageId = item.pageid;
						for (featuredItemCount; featuredItemCount < pageSizeValue*pageNum && featuredItemCount < featuredItem.length; featuredItemCount++) {
							var featuredcardsData = featuredCard(featuredItem[featuredItemCount]);
							$featuredContainer.append (featuredcardsData);
						}
					}

					//IF THERE ARE RESULTS & THEY ARE ADDITIONAL RESULTS THEN BUILD OUT BASED ON CARDS DATA
					if (data.Values.length > 0 && getContentType === 'allresults') {
						if(savedItem.length > 0) { //carry over 10th result until featured page shows up
							 $.merge(items, savedItem);
							 savedItem = [];
						}

						if (item.pageid != featuredPageId) { //only add pages to array if they don't match the featured page
							if (featuredPageId !== 0 && !foundFeaturedPage && itemCount === pageSizeValue-1*pageNum) { 
								//if there is a featured page && the featured page was not found in this set && it is the last result in the set (dont show it)
								//do not add 10th page if a featured page exists and has already been added and the featured page was not eliminated in this pull of results
								savedItem.push(item); //save last item in set if featured page is not in set of results and carry over to next set
							} else {
								items.push(item);
							}
						} else {
							foundFeaturedPage = true;
						}
						//IF THERE ARE RESULTS THEN BUILD OUT BASED ON CARDS DATA
						for (itemCount; itemCount < pageSizeValue*pageNum && itemCount < items.length; itemCount++) {
							var cardsData = cards(items[itemCount]);
							$container.append(cardsData);
						}

						//IF WE HAVE A FEATURED IMAGE THEN REMOVE ONE FROM THE TOTAL RECORDS BELOW TO DIVIDE BY PAGE SIZE VALUE
						if (foundFeaturedPage) {
							pages = Math.ceil((totalRecords - 1) / pageSizeValue);
						} else {
							pages = Math.ceil(totalRecords / pageSizeValue);
						}
					}

					//IF THERE ARE RESULTS & THEY ARE ADDITIONAL RESULTS THEN BUILD OUT BASED ON CARDS DATA
					if (data.Values.length > 0 && getContentType === 'filteredresults') {
						foundFeaturedPage = false;
						var cardResultsData = cards(item);
						$container.append (cardResultsData);
					}

					runAfterPageLoad();

				}

				

				//ADDING THE LOAD MORE BUTTON IF MORE THAN SET NUMBER OF RESULTS & FUNCTIONALITY OF LOAD MORE BUTTON
				if (getContentType === 'allresults' || getContentType === 'filteredresults') {
				//IF THERE ARE NO RESULTS THEN GIVE THE NO RESULTS MESSAGE
					if (totalRecords === 0) {
						$('#filter-display').append ('<div class="NoResults"><p>Sorry, no results were found.</p></div>');
					}

					//ADDING THE VIEW MORE BUTTON IF MORE THAN SET NUMBER OF RESULTS & FUNCTIONALITY OF VIEW MORE BUTTON
					if (pageNum < pages && pages > 1) {
						$('#containerWrap').append('<div id="ViewMore"><button>Load More Blogs</button></div>');
						$('#ViewMore button').click(function () {
							pageNum++;
							getContent( getContentType, relatedTermIds, keywordValue, sort, pageSizeValue, pageNum );
							runAfterPageLoad();
							VideoIcon();
						});
					}
				}
				$('.Loading').remove();

				// TRIGGERING gotContent SO THAT FUNCTION ON PAGE LOAD ONLY WAITS FOR THIS TO COMPLETE
				$(window).trigger("gotContent");
			}//end else

		});
	}

	function renderOutstandingContent(items, container) {
		let lastItem = items.at(-1);
		let cardsData = cards(lastItem);
		container.append (cardsData);
		$('#ViewMore').remove();
	}

	// MANAGE THE CHECKBOXES (IF LAST CHECKBOX IS UNCHECKED THEN AUTO CHECK THE ALL CHECKBOX OR IF ALL IS SELECTED UNCHECK ALL OTHER CHECKBOXES)
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageCheckbox( $checkbox ) {
		relatedTermIds = originalRelatedTermIds;
		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCheckboxLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[ group ];
		if ( !filterGroup ) {
			filterGroup = filters[ group ] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)
		var index = $.inArray( checkbox.value, filterGroup );

		// CHECKING THE ALL CHECKBOX WITHIN A GROUP
		if ( isAll ) {

			//remove all filters from the group
			delete filters[ group ];

			//if checkmark is not checked then check it
			if ( !isAll.checked ) {
				checkbox.checked = 'checked';
			}

			//removing all other related terms from the group from the relatedTermIds criteria to pull back content
			relatedTermIds = relatedTermIds.filter( function( el ) {
				return filterGroup.indexOf( el ) < 0;
			} );

			//remove filtered by buttons for individual group when you check all
			$('button.FilterItem').each(function() {
				if ($.inArray($(this).attr('data-id'), filterGroup) !== -1) {
					$(this).remove();
				}
			});
		}

		// CHECKING ANY CHECKBOX WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		if ( checkbox.checked ) {
			var selector = isAll ? 'input' : 'input.all';

			//remove check from all checkbox
			$(otherCheckboxLabelsInGroup).children( selector ).removeAttr('checked');

			if ( !isAll && index === -1 ) {
				// add filter to group
				filters[ group ].push( checkbox.value );
				relatedTermIds.push( checkbox.value );

				//build out filtered by area
				$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
			}
		}

		// UNCHECKING A CHECKMARK WITHIN A GROUP, EXCEPT THE ALL CHECKBOX
		else if ( !isAll ) {
			// remove filter from group
			filters[ group ].splice( index, 1 );
			if (( index = relatedTermIds.indexOf( checkbox.value )) !== -1) {
				relatedTermIds.splice( index, 1 );
			}
			// if unchecked the last box, check the all
			if ( !$(otherCheckboxLabelsInGroup).children().is( ":checked" ) ) {
				$(otherCheckboxLabelsInGroup).children('input.all').attr('checked', 'checked');
			}
			//removing filtered by buttons when unchecked from drop down
			$('button.FilterItem').each(function() {
				if ($(this).attr('data-id') === checkbox.value) {
					$(this).remove();
				}
			});
		}
	}

	// MANAGE MULTIPLE CHECKBOXES (THIS OCCURS WHEN HASH TAGS ARE ADDED TO THE URL)
	// THIS ALSO DEFINES THE UPDATED VALUES OF THE RELATEDTERMIDS TO RUN THE API WITH
	//====================================================
	function manageMultipleCheckboxes( $checkbox ) {
		var checkbox = $checkbox[0],
			checkboxLabel = $checkbox.parents('label'),
			otherCheckboxLabelsInGroup = checkboxLabel.siblings('label'),
			group = $checkbox.parents('.option-set').attr('data-group'),
			isAll = $checkbox.hasClass('all');

		//OPTIONS FOR FILTERED BY AREA
		var checkboxText = $(checkboxLabel).text(),
			filterIdClass = $(checkboxLabel).attr('for');

		// CREATE ARRAY FOR FILTER GROUP, IF NOT THERE YET
		var filterGroup = filters[ group ];
		if ( !filterGroup ) {
			filterGroup = filters[ group ] = [];
		}

		// INDEX OF (KEEPS TRACK OF PLACEMENT WITHIN THE ARRAY)
		var index = $.inArray( checkbox.value, filterGroup );

		var selector = isAll ? 'input' : 'input.all';
		//since there is not click we need to force the active checked state
		$checkbox.attr('checked', 'checked');

		//remove check from all checkbox
		$(otherCheckboxLabelsInGroup).children( selector ).removeAttr('checked');

		if ( !isAll && index === -1 ) {
			// add filter to group
			filters[ group ].push( checkbox.value );
			relatedTermIds.push( checkbox.value );
			//build out filtered by area
			$('#filter-display').prepend('<button class="FilterItem" data-id="' + filterIdClass + '"><span class="Hide">Remove </span>' + checkboxText + '<span class="Hide"> filter</span><span class="CloseIcon" aria-hidden="true"></span></button></span>');
		}

		// Show / Hide filter-display
		var filterDisplay = $('#filter-display');
		if ( filterDisplay.find('.FilterItem').length > 0) {
			filterDisplay.addClass('Active');
		} else {
			filterDisplay.removeClass('Active');
		}
	}

	// ON RESIZE - ACTIVATE FADE OVERLAY ON FILTER LIGHTBOX FUNCTION
	//===================================================
	$(window).resize(function () {
		activateFadeOverlay();
	});

	//ADDING CLASS TO FADE OVERLAY ON FILTER LIGHTBOX ONLY WHEN SCROLL BAR IS THERE
	//====================================================
	function activateFadeOverlay() {
		if ($('.option-set.Active').length > 0) {
			var activeOptionSet = ($('.option-set.Active').outerHeight());
			var activeOptionSetContents = ($('.option-set.Active .option-set-header').outerHeight() + $('.option-set.Active .option-set-contents').outerHeight() + 3);

			if (activeOptionSet < activeOptionSetContents) {
				$('.FadeOverlay').addClass('Active');
			}
			else {
				$('.FadeOverlay').removeClass('Active');
			}
		}
	}

	//SEARCH OR FILTER RESETS - REMOVING OR UPDATING ELEMENTS WHEN NEW SEARCH OR FILTER OCCURS
	//====================================================
	function resetPageElements() {
		$('#SearchedBy, .NoResults, #ContentSearch .error, #containerWrap #ViewMore, .featuredItem').remove();
		$container.find('.item').remove();
		$container.css('height', 'auto');
	}

	// ANY CLICK OUTSIDE OF THE FILTERS LIGHTBOX AREA WILL CLOSE AN ACTIVE FILTER OPTIONS BOX
	//====================================================
	$(document).on('click', function (e) {
		if ($('#filter').has(e.target).length === 0) {
			$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
		}
	});

	// CLICKING ON THE X ON THE FILTER BOX WILL CLOSE ACTIVE FILTER OPTIONS BOX
	//====================================================
	$('.ContentHub').on('click', 'button.FilterCloseButton', function (ele) {
		ele.preventDefault();
		// Reset featuredContent flag.
		foundFeaturedPage = false;
		$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
	});

	// ANY CLICK OUTSIDE OF THE FILTERS LIGHTBOX AREA WILL CLOSE AN ACTIVE FILTER OPTIONS BOX
	//====================================================
	$('.ContentHub').on('click', '.modal-overlay', function (ele) {
		ele.preventDefault();
		// Reset featuredContent flag.
		foundFeaturedPage = false;
		$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
	});

	// LOOPING BACK TO FIRST INPUT BOX IF TABBING PAST THE CLOSE BUTTON OR REVERSE
	//====================================================
	$('.ContentHub').on('keydown', 'button.FilterCloseButton', function (e) {
		// tab only: Focus next input
		if((e.which === 4) && (!e.shiftKey)) {
			$(this).parents('.option-set.Active').find('label:first-child input').focus();
			return false;
		}
	});
	$('.ContentHub').on('keydown', 'input.all', function (e) {
		// tab + shift: Focus previous input
		if((e.which === 4) && (e.shiftKey)) {
			$(this).parents('.option-set.Active').find('button.FilterCloseButton').focus();
			return false;
		}
	});

	// IF FILTER IS OPEN AND ESC KEY IS PRESSED THEN CLOSE FILTERS
	//====================================================
	$(document).on('keydown', function (e) {
		// esc key
		if(e.which === 27) {
			if ($('.option-set.Active').length) {
				$($('#filter .option-set.Active').prev('#filter button.Active')).trigger('click');
			}
		}
	});

	// REFORMATTING PUBLICATION DATE
	//====================================================
	function convertDate(date) {
		var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			splitDate = date.split('-');
		return months[parseInt(splitDate[1])-1] + " " + splitDate[2] + ", " + splitDate[0];
	}

	// CLICKING ON SHARE ICON ON INDIVIDUAL CONTENT CARDS OPENS SOCIAL ICONS
	//====================================================
	
});

// ====== END HUB HOME SPECIFIC SCRIPTS ====== //

//======= BEGINNING HUB HOME OR INTERIOR SCRIPTS ====== //
// SLIDER FUNCTIONALITY
function sliders() {
	define('jquery', function() {
		return window.jQuery;
	});

	requirejs.config({
		baseUrl: "/scripts",
		paths: {
			slick: 'slick',
			require: '3rdparty/require/2.1.20'
		}
	});  
	var jqVs = $;
	jqVs(function() {
		requirejs( ['require', 'jquery', 'slick'], function(require, $) {
			$('.Slider').not('.slick-initialized').on('afterChange',function(){
				$('iframe').each(function(){ //on change slide = do action (pause iframe video)
					$(this)[0].contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
				});
			}).slick({
				dots: false,
				arrows: true,
				infinite: true,
				autoplay: false,
				autoplaySpeed: 8000,
				speed: 500,
				fade: true,
				cssEase: 'linear'
			});
			$('.cmspanel.Hub.ItemsLoaded .Hub-Stories').not('.slick-initialized').slick({
				dots: false,
				infinite: false,
				slidesToShow: 3,
				slidesToScroll: 1,
				responsive: [
					{
						breakpoint: 1023,
						settings: {
							slidesToShow: 2
						}
					},
					{
						breakpoint: 800,
						settings: {
							slidesToShow: 1
						}
					}
				]
			});
		});
	});
}

function relatedAuthorLinks() {
	var contentAuthor = '';

	if (("taxonomy" in VitalSite) && VitalSite.taxonomy !== null) {
		var taxonomy = VitalSite.taxonomy;
		$(taxonomy).each(function (i, element) {
			if (element.FacetName === 'Balance Author') { //build out the Beat author container once - only if authors exist
				$('.PagePublicationDate').append("<span id='Hub-Author'><p class='AuthorText'></p></span>");
			}

			$(element.Terms).each(function (j, elm) { //loop through all of the terms for the page
				if (element.FacetName === 'Balance Author') { //found 'Content Hub Author'
					contentAuthor = (elm.Name);
					var contentAuthorHash = (contentAuthor.toString().replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g,''));
					$('.AuthorText').append("<span>" + contentAuthor + "</span>, ");
				}
			});
		});
	}

	var strVal = $.trim($('.AuthorText').html());

	//remove related link if only _No Author is marked
	strVal = strVal.replace('<a href="/balance/#NoAuthor">_No Author</a>,', '');

	//removing last space from tag list
	var lastChar = strVal.slice(-1);
	if (lastChar === ' ') {
		strVal = strVal.slice(0, -1);
	}
	//removing last comma from tag list
	lastChar = strVal.slice(-1);
	if (lastChar === ',') {
		strVal = strVal.slice(0, -1);
	}

	$(".AuthorText").html(strVal);

	if($('.AuthorText').is(':empty')) {
		$('#Hub-Author').remove();
	}
}

// BUILD OUT PUB DATE
//====================================================
function buildPubDate(cmspage) {
	var pubDate = cmspage.OriginalPublicationDate;
	return convertDate(pubDate);
}

// Add Video Icon
//====================================================
function VideoIcon() {
	$('.ContentHub #container .item').each(function() {
		var VideoIcon = $(this).find(".VideoIcon");
		if ($(this).find('.Video').length) {
			if($(VideoIcon).length) {
            //do nothing if Video Icon already added
			} else {
				$(this).find('.ContentWrap').prepend('<span class="VideoWrap"><span class="VideoText">Video</span><span class="VideoIcon"><img alt="" height="27" src="/imgs/icons/video-icon.svg" width="27"></span></span>');
			}
		}
	});
}

// REFORMATTING PUBLICATION DATE
//====================================================
function convertDate(date) {
	var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
		splitDate = date.split('-');
	return months[parseInt(splitDate[1])-1] + " " + splitDate[2] + ", " + splitDate[0];
}

// HUB PANEL CONTENT MARKUP
//====================================================
var panelCards = function (page) {
	var element = "<article aria-labelledby='article" + page.pageid + "' class='item " + page.balanceContentType + " " + page.contentTopic + " " + page.serviceLines + " " + page.contentAuthor + "' id='" + page.pageid + "'>" +
				"<a class='" + page.balanceContentType + "' href='" + page.url + "'>" + 
					"<span class='ImageWrap2'><span class='ImageWrap'>" + page.image + "</span></span>" +
					"<span class='ContentWrap'>" +
						"<span class='Date'>" + page.newDateFormat + "</span>" +
						"<span class='Title' id='article" + page.pageid + "' role='heading' aria-level='2'>" + page.title + "</span>" + 
						"<span class='Description'>" + page.snippet + "</span>" +
					"</span>" +
				"</a>" +
			"</div>" +
		"</article>";
	return element;
};

// SETTING VARIABLES FOR HUB STORIES PANEL
//====================================================
var totalRecords = '',
	pageNum = 1,
	pageSizeValue = 10,
	hubPanelLoaded = 'no',
	items = [],
	itemCount = 0,
	currentPageId = $('meta[name="CmsPageId"]').attr('content');
	snippet = $('meta[name="description"]').attr('content');
	relatedTermIds = originalRelatedTermIds;

if (("taxonomy" in VitalSite) && VitalSite.taxonomy !== null) {
	var taxonomy = VitalSite.taxonomy;
	$(taxonomy).each(function (i, element) {
		$(element.Terms).each(function (j, elm) { //loop through all of the terms for the page
			if (element.FacetName === 'Service Line' || element.FacetName === 'Balance Topic') { //found 'Services' or 'Content Topic'
				relatedTermIds.push( elm.TermId );
			}
		});
	});
}

// GETTING CONTENT FOR HUB STORIES PANEL
//====================================================
function getHubPanelContent( hubPanelLoaded, relatedTermIds, pageSizeValue, pageNum, currentPageId ) {
	$.getCmsPages({
		pageNumber: pageNum,
		pageSize: pageSizeValue,
		sort: 4,
		fields  : "PageId, HtmlTitle, publichref, originalPublicationDate, HtmlContent, Images, Taxonomy, Description",
		criteria: {
			originalpublicationbegindate: "01/01/2012",
			relatedTermIds: relatedTermIds
		}
	}, function (data) {
		//store totalRecords in variable
		totalRecords = data.TotalRecords;
		//var pages = Math.ceil(totalRecords / pageSizeValue);
		
		for (var i in data.Values) {
			var cmspage = data.Values[i];

			var item = {
				title: cmspage.HtmlTitle,
				pageid: cmspage.PageId,
				url: cmspage.PublicHRef.replace(/~/g, ''),
				newDateFormat: buildPubDate(cmspage),
				snippet: cmspage.Description,
				imageSrc: (typeof cmspage.Images[0] !== 'undefined') ? "/app/files/public/" + cmspage.Images[0].FileName : "/imgs/Hub-Thumb.jpg",
				image: (typeof cmspage.Images[0] !== 'undefined') ? "<img alt='" + cmspage.Images[0].FileTitle + "' src='/app/files/public/" + cmspage.Images[0].FileName + "'/>" : "<img alt='' src='/imgs/Hub-Thumb.jpg' />",
				balanceContentType: '',
				contentTopic: '',
				eachTopicTermWithNobr: '',
				contentTopicTag: '',
				serviceLines: '',			
				contentAuthor: '',
				eachAuthorTermWithNobr: '',
				contentAuthorTag: '',
				topicTerms: [],
				serviceTerms: [],
				authorTerms: []
			};

			for (i in cmspage.Taxonomy) {

				//set variable to taxonomyy object
				var Taxonomy = cmspage.Taxonomy[i];

				//check for taxonomy = Balance Type
				if (Taxonomy.FacetName === 'Balance Type') {
					//loop through taxonomy for terms
					for (i in Taxonomy.Terms) {
						//set variable to terms object
						var balanceTerm = Taxonomy.Terms[i];
						//add terms to array
						item.balanceTerms.push(balanceTerm.Name);
					}
					item.balanceContentType = (item.balanceTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
				}

				//check for taxonomy = Content Topic
				if (Taxonomy.FacetName === 'Balance Topic') {
					//loop through taxonomy for terms
					for (i in Taxonomy.Terms) {
						//set variable to terms object
						var topicTerm = Taxonomy.Terms[i];
						//add terms to array
						item.topicTerms.push(topicTerm.Name);
					}
					item.contentTopic = (item.topicTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
					//(this takes topics from taxonomy and if it's not undefined & it's not marked "_No Topic" then set any others to this variable), also add in comma between each Topic term
					item.eachTopicTermWithNobr = "<span class='nobr'>" + (item.topicTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
					item.contentTopicTag = (item.topicTerms.toString() !== 'undefined' && item.topicTerms.toString() !== '_No Topic') ? "<div class='Topic'><span>" + item.eachTopicTermWithNobr + "</span></div>" : "";
				}

				//check for taxonomy = Services
				if (Taxonomy.FacetName === 'Service Line') {
					//loop through taxonomy for terms
					for (i in Taxonomy.Terms) {
						//set variable to terms object
						var serviceTerm = Taxonomy.Terms[i];
						//add terms to array
						item.serviceTerms.push(serviceTerm.Name);
					}
					item.serviceLines = (item.serviceTerms.toString().replace(/\s+/g, '').replace(/,/g, ' ').replace(/[^a-zA-Z 0-9]+/g,''));
				}

				//check for taxonomy = Content Author
				if (Taxonomy.FacetName === 'Balance Author') {
					//loop through taxonomy for terms
					for (i in Taxonomy.Terms) {
						//set variable to terms object
						var authorTerm = Taxonomy.Terms[i];
						//add terms to array
						item.authorTerms.push(authorTerm.Name);
					}
					item.contentAuthor = (item.authorTerms.toString().replace(/\s+/g, '').replace(/[^a-zA-Z 0-9]+/g,''));
					//(this takes authors from taxonomy and if it's not undefined & it's not marked "_No Author" then set any others to this variable), also add in comma between each author term
					item.eachAuthorTermWithNobr = "<span class='nobr'>" + (item.authorTerms.toString().replace(/,/g, ',</span> <span class="nobr">')) + "</span>";
					item.contentAuthorTag = (item.topicTerms.toString() !== 'undefined' && item.authorTerms.toString() !== '_No Topic') ? "<div class='Topic'><span>" + item.eachAuthorTermWithNobr + "</span></div>" : "";
				}

			} //end taxonomy loop
			
			if (data.Values.length > 0 && $('.Hub-Stories').length) {
				if (item.pageid == currentPageId) {
					totalRecords = (totalRecords - 1);
				} else {
					items.push(item);
				}
			}
			
			if (totalRecords > 0) {
				//IF THERE ARE RESULTS THEN BUILD OUT BASED ON CARDS DATA
				for (itemCount; itemCount < pageSizeValue * pageNum && itemCount < items.length; itemCount++) {
					var cardsData = panelCards(items[itemCount]);
					//Hub panels will have .Hub-Stories
					$('.Hub-Stories').append(cardsData);
				}
				$('.cmspanel.Hub').addClass('ItemCount' + totalRecords);

				if (hubPanelLoaded === 'Yes') { //hubPanelLoaded is 'no' until final api call is made
					$('.cmspanel.Hub').addClass('ItemsLoaded');
					sliders();
				}
			} else {
				$('.cmspanel.Hub').remove();
			}
		}
	});
}

//====== END HUB HOME OR INTERIOR SCRIPTS ===== //

/* ===================================================
	WINDOW LOAD - Runs AFTER the entire page has loaded
=================================================== */
$window.on('load', function () {
	'use strict';
	/*if (!$('#Blank').length) {

	}*/
});

/* ===================================================
	WINDOW RESIZE - Runs as soon as the window is resized
=================================================== */
$window.resize(function () {
	'use strict';
	if (isAndroidDevice && $('input').is(':focus')) {
		//do nothing if input has focus
	} else {

		// if (!$('.AccessControls').length) {
		// 	addAccessControls();
		// }
	}
});

/* ===================================================
	ORIENTATION CHANGE - Runs as soon as the screen orientation is changed
=================================================== */
$window.on('orientationchange', function () {
	'use strict';
});

/* ===================================================
	DOC READY - Runs as the page is loading
=================================================== */

$(document).ready(function () {
	relatedAuthorLinks();

	$('#AbovePageContent .cmspanel').prepend('<span class="top-color-bar"><span class="Dec1"></span></span>');

	/* Panels for Hub Stories
	------------------------------------------------------------------ */
	// $('.cmspanel').each(function() {
	// 	if ($(this).find('.Hub-Stories').length) {
	// 		$(this).addClass('Hub');
	// 	}
	// });
	// if ($('.Hub-Stories').length) {
	// 	// running page API for: Content Hub Page term
	// 	hubPanelLoaded = 'Yes';
	// 	pageSizeValue = 4;
	// 	getHubPanelContent( hubPanelLoaded, relatedTermIds, pageSizeValue, pageNum, currentPageId );
	// }

	// /* HUB INTERIOR - if empty h1 tag exists then remove it
	// ----------------------------------------------------------------- */
	$('h1').each(function(){
		if($(this).is(':empty')){
		  $(this).remove()
		}
	 });

	/* HUB INTERIOR ONLY - replace pub date format
	----------------------------------------------------------------- */
	if ($('body.ContentInterior .PagePublicationDate').length) {
		// var pubDateStr = $('.PagePublicationDate').clone().children().remove().end().text();
		// var match = pubDateStr.match(/(\w*)([ \d]*,)([ \d]*)/);
		// var newPubDateStr = match[1] + match[3];
		// $('.PagePublicationDate').html('<strong class="sr-only">Published on</strong> ' + newPubDateStr);
		$('.PagePublicationDate').addClass('Show');
		$('.PagePublicationDate, .Hub-Author').wrapAll('<div class="Date-Time"></div>');
	}

	/* HUB INTERIOR - if old CTA in use add class and styles - used for past posts
	----------------------------------------------------------------- */
	if ($('#CallToAction .cmsPanelContent').length > 0) {
		$('.ContentInterior').addClass('PastPost');
		$('.cmsPageContent').attr('id','ContentWrap');

		org_html = document.getElementById("ContentWrap").innerHTML;
		new_html = "<div class='ContentInnerWideWrap'><div id='article' class='ContentInnerWide'>" + org_html + "</div></div>";
		document.getElementById("ContentWrap").innerHTML = new_html;

		$('h1').insertBefore('.ContentInnerWideWrap').wrap('<div class="Post-Title DarkGreen-Background"><div class="TitleWrap"></div></div>');
		$('.FeaturedImgWrap').insertBefore('.Post-Title');
		$('.Date-Time').prependTo('.ContentInnerWideWrap');
		$('.Date-Time').append('<div id="ReadTime"><p><span id="time"></span> minute read</p></div>');

		/* HUB INTERIOR ONLY - if video exists add jump link button
		----------------------------------------------------------------- */
		if ($('.ContentInterior .ContentInnerWide iframe[src*="youtube.com"], .ContentInterior .ContentInnerWide iframe[src*="youtube-nocookie.com"]').length) {
			$('.Post-Title .TitleWrap').append('<p class="WatchVideo"><a class="Button" href="#Video"><span>Watch Video</span><span class="VideoIcon"><img class="white" alt="" height="27" src="/imgs/icons/video-icon-white.svg" width="27"><img class="black" alt="" height="27" src="/imgs/icons/video-icon-black.svg" width="27"></span></a></p>');
		}

		/* HUB INTERIOR ONLY - if balance form exists add jump link button
		----------------------------------------------------------------- */
		if ($('#CallToAction .cmsPanelContent .avera-balance-form').length) {
			$('#CallToAction').addClass('BalanceFormCTA');
			$('.Post-Title .TitleWrap').append('<p class="Subscribe"><a class="LimeGreenArrowLink" href="#CallToAction">Subscribe to our wellness newsletter</a></p>');
		}

		$('#CallToAction:not(.BalanceFormCTA)').appendTo('.ContentInnerWide');
	}

	/* HUB INTERIOR - Move Pub Date and Read Time for new article posts
	----------------------------------------------------------------- */
	$('.ContentInterior').each(function() {
		var firstElementWithClass = document.querySelector('.ContentInnerWide');
		$('.ContentInterior:not(.PastPost) .cmsPageContent').attr('id','article');
		$('.ContentInterior:not(.PastPost) .Date-Time').prependTo(firstElementWithClass);
		$('#Hub-Author').appendTo('.Date-Time');
		$('.ContentInterior:not(.PastPost) .Date-Time').append('<div id="ReadTime"><p><span id="time"></span> minute read</p></div>');

		if ($('#article').length) {
			function readingTime() {
				const text = document.getElementById("article").innerText;
				const wpm = 225;
				const words = text.trim().split(/\s+/).length;
				const time = Math.ceil(words / wpm);
				document.getElementById("time").innerText = time;
			}
			readingTime();
		}
	});

	/* if any p tag in div.cmspage or div.cmspanel is empty, let's remove it.
	----------------------------------------------------------------- */
	$('div.cmspage p, div.cmspanel p, #BelowPageContent').each(function (i, el) {
		// Remove white space
		var html = $(el).html().replace(/\s+/, '');
		// Check if element is :empty or length of html is zero
		if ($(el).is(':empty') || html.length === 0) {
			$(el).remove();
		}
	});

	/*  Remove margin-bottom from paragraphs with a button inside of them
		The p tag is automatically wrapped around anchors in TinyMCE
	----------------------------------------------------------------- */
	$('.Button').each(function () {
		if ($(this).parent('p')) {
			$(this).parent('p').addClass('NoSpace');
		}
	});

	/* Add TableContainer around tables to make scrollable on small screens
	---------------------------------------------------------------- */
	$('.cmspage table, .cmspanel table').each(function() {
		$(this).wrap('<div class="TableContainer" />');
	});
});

